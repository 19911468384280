// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cloudphysics-tsx": () => import("./../../../src/pages/cloudphysics.tsx" /* webpackChunkName: "component---src-pages-cloudphysics-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lanamark-one-tsx": () => import("./../../../src/pages/lanamark-one.tsx" /* webpackChunkName: "component---src-pages-lanamark-one-tsx" */),
  "component---src-pages-liveoptics-tsx": () => import("./../../../src/pages/liveoptics.tsx" /* webpackChunkName: "component---src-pages-liveoptics-tsx" */),
  "component---src-pages-partners-lenovo-tsx": () => import("./../../../src/pages/partners/lenovo.tsx" /* webpackChunkName: "component---src-pages-partners-lenovo-tsx" */),
  "component---src-pages-partners-nutanix-tsx": () => import("./../../../src/pages/partners/nutanix.tsx" /* webpackChunkName: "component---src-pages-partners-nutanix-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-partners-vmware-tsx": () => import("./../../../src/pages/partners/vmware.tsx" /* webpackChunkName: "component---src-pages-partners-vmware-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rvtools-tsx": () => import("./../../../src/pages/rvtools.tsx" /* webpackChunkName: "component---src-pages-rvtools-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

